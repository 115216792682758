<template lang="pug">
div(v-if="maxCashPayment || (cashValues && cashValues.length)")
  .costs-list-subtitle.m-t-60
    strong ÖNSKAD KONTANTINSATS, KR
  .costs-list-slider.costs-slider-cash-payment
    vue-slider(
      :key="1"
      :class={'cost-slider-with-marks' : cashValues}
      v-if="cashValues"
      :data="cashValues"
      v-model="cashPayment"
      :dotSize="slider.dotSize"
      :height="slider.height"
      :silent="true"
      :contained="true"
      :drag-on-click="true"
      :tooltip="'always'"
      @drag-end="sliderChange($event)"
      :tooltip-formatter="formatter1"
      :data-value="'percentVal'"
    )
    vue-slider(
      v-else
      :key="2"
      :min="minCashPayment"
      :max="maxCashPayment"
      v-model="cashPayment"
      :dotSize="slider.dotSize"
      :height="slider.height"
      :silent="true"
      :contained="true"
      :drag-on-click="true"
      :tooltip="'always'"
      @drag-end="sliderChange($event)"
      :tooltip-formatter="formatter2"
    )


</template>

<script>

import CostsMixin from '@/mixins/CostsMixin'
import CostsSliderMixin from '@/mixins/CostsSliderMixin'

export default {
  mixins: [
    CostsMixin,
    CostsSliderMixin,
  ],
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
  },
  computed: {
    formatter1() {
      return this.formatPrice(this.calculatedCashPaymentPrice).toString()
    },
    formatter2() {
      return (
        this.formatPrice(this.calculatedCashPaymentPrice) +
        ' kr (' +
        this.cashPayment +
        '%)'
      )
    },
    costPrice() {
      return this.getFormCostPrice('Private leasing')
    },
  },

}
</script>
